import React, { useEffect,useCallback } from 'react';
import { Box, Container, Typography, Grid, IconButton, Button, useTheme, useMediaQuery } from '@mui/material';
import { ArrowBack, VisibilityOutlined, ChatBubbleOutline } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 
import heroImage from './images/background/detailabout.png'; 
import blogImage from './images/about/life3.jpeg';
import Footer from './Footer';
import tailor1 from './images/about/tailor1.jpeg';
import tailor2 from './images/about/tailor.jpeg';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));


const SidebarSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 6px 24px rgba(0,0,0,0.1)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)( {
  fontSize: '1.7rem',
  fontWeight: 600,
  marginBottom: '1rem',
});
const DonateButton = styled(Button)( {
  backgroundColor: ' #d40032',
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: '24px',
  padding: '10px 20px',
  marginTop: '16px',
  '&:hover': {
    backgroundColor: '#b30029',
  },
});
const SectionContent = styled(Typography)( {
  fontSize: '1rem',
  color: '#555',
  marginBottom: '1rem',
});
const Lifeschooldetail = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
        const donateSection = document.getElementById('donate-us-section');
        if (donateSection) {
            donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } 
    }, 100);
}, [navigate]);
  
  const blog = {
    title: 'Stitching Dreams: Ray of Love Tailoring Training Center Empowers Women in Gudalur',


    date: 'May 4, 2024',
    author: 'Shajan John Edakkad',
    views: 123,
    comments: 45,
    content: `
        <p style="text-align: justify; line-height: 1.5;">In the heart of Seaforth village, Gudalur, within the serene Nilgiris district, a quiet transformation is taking place. At the Ray of Love Tailoring Training Center, women are not just learning to sew; they’re stitching together threads of hope, independence, and a brighter future. This initiative, driven by the Ray of Love Development Foundation, is designed to empower women with essential tailoring skills, opening doors to new livelihoods and financial stability.
The journey for many of these women began with a vision: a desire to contribute to their families, stand on their own feet, and build a life of dignity and self-sufficiency. However, for those in rural areas, resources and opportunities can often be limited. That’s where the Ray of Love Tailoring Training Center steps in, providing a nurturing space where women of Seaforth and neighboring villages can learn marketable skills, create high-quality garments, and gain confidence in their abilities.

</p>
      <img src="${tailor1}" alt="Blog Detail" style="width: 100%; margin: 20px 0;" />


     
  <p style="text-align: justify; line-height: 1.5;"> Supported by dedicated trainers and resources, the center offers a structured program where women learn the basics of tailoring, from stitching techniques and fabric handling to designing and creating custom pieces. The training goes beyond just technical skills; it fosters resilience, creativity, and entrepreneurial spirit among the participants. Many women who complete the program find they can start small businesses, either working from home or joining cooperative tailoring units in their communities.
The benefits extend beyond economic gain. As these women acquire new skills, they experience personal growth and a sense of community with fellow trainees. Many have discovered newfound purpose and identity, taking pride in their craft and achievements. The center has become a place where they not only gain practical skills but also build lifelong connections, share stories, and support each other on their journeys.

</p>
   <img src="${tailor2}" alt="Blog Detail" style="width: 100%; margin: 20px 0;" />


</b></p>
  <p style="text-align: justify; line-height: 1.5;">The success of the Ray of Love Tailoring Training Center is a testament to the power of community-driven initiatives. By equipping women with tools and training, the Foundation is helping them create sustainable livelihoods and positively impact their families and community. As these women step forward with confidence and ambition, they embody the true spirit of empowerment.
In Seaforth, the hum of sewing machines and the laughter of determined women fill the air—a melody of hope and a testament to what’s possible when a community comes together to lift each other up. The Ray of Love Tailoring Training Center is more than just a place to learn; it’s a space to grow, connect, and thrive.

</p>




 
    `,
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8' }}>
      {/* Hero Section */}
      <HeroSection>
        <HeroContent>
          <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xs: '1.5rem', md: '2.5rem' }, marginBottom: 2 }}>
          Tailoring Center: Stitching Future
          </Typography>
          {/* <Typography variant="h7" sx={{ fontWeight: 400, fontSize: { xs: '1rem', md: '1.3rem' }, marginBottom: 4 }}>
          Let’s rock and sing, let’s grow together!
          </Typography> */}
        </HeroContent>
      </HeroSection>
      



        {/* Blog Content Section */}
        <Container>
        <Grid container spacing={3} sx={{ my: 4 }}>
          <Grid item xs={12} md={12} sx={{ mb: 4 }}  >
            <Box sx={{ backgroundColor: 'white', borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.1)', p: 4 }}>
              <Typography variant="caption" sx={{ color: '#888', mb: 1 }}>
                {blog.date}
              </Typography>
              <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 , fontSize: { xs: '1.5rem', md: '2rem' },}}>
                {blog.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#555', mb: 2 }}>
                by {blog.author}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                  <VisibilityOutlined sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.views} views</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChatBubbleOutline sx={{ fontSize: 20, color: '#d40032 ' }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{blog.comments} comments</Typography>
                </Box>
              </Box>
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </Box>
          </Grid>

          {/* Sidebar Section */}
          <Grid container spacing={2} style={{ marginLeft: 4 }}>

          <Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Recent Projects</SectionTitle>
    <SectionContent>
      <a href="/lifeschool/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Unique LifeSchool Programme</a>
    </SectionContent>
    <SectionContent>
      <a href="/gudalur/1" style={{ textDecoration: 'none', color: ' #d40032' }}>From Floor to Bench: Gudalur</a>
    </SectionContent>
    <SectionContent>
      <a href="/tailoring/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Tailoring Center:Stitching Future</a>
    </SectionContent>
  </SidebarSection>
</Grid>
<Grid item xs={12} lg={4}>
<SidebarSection>
    <SectionTitle>Get Involved</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Wany to make a difference? Check out our programs and see how you can help.
    </Typography>
    <DonateButton fullWidth>Join Us</DonateButton>
  </SidebarSection>
</Grid>

<Grid item xs={12} lg={4}>
  <SidebarSection>
    <SectionTitle>Support Our Cause</SectionTitle>
    <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
      Your donations help us continue our mission to improve education for underprivileged children.
    </Typography>
    <DonateButton fullWidth onClick={scrollToDonate} >Donate</DonateButton>
  </SidebarSection>
</Grid>
</Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
};

export default Lifeschooldetail;
