import React, { useEffect, useState, useCallback } from 'react';
import { Box, Container, Typography, Grid, Button, useTheme, useMediaQuery } from '@mui/material';
import { VisibilityOutlined, ChatBubbleOutline } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom'; 
import { getProjectById } from '../Services/AdminServices'; // Adjust the import path for your API method
import Footer from './Footer';
import heroImage from './images/background/detailabout.png';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  padding: theme.spacing(8, 0),
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));

const SidebarSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 6px 24px rgba(0,0,0,0.1)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)( {
  fontSize: '1.7rem',
  fontWeight: 600,
  marginBottom: '1rem',
});

const DonateButton = styled(Button)( {
  backgroundColor: ' #d40032',
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: '24px',
  padding: '10px 20px',
  marginTop: '16px',
  '&:hover': {
    backgroundColor: '#b30029',
  },
});

const SectionContent = styled(Typography)( {
  fontSize: '1rem',
  color: '#555',
  marginBottom: '1rem',
});

const ProjectDetail = () => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg')); // Detect lg screens
  const { id } = useParams(); // Get project ID from the URL
  const navigate = useNavigate();

  // Scroll to Donate Section after redirect
  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
      const donateSection = document.getElementById('donate-us-section');
      if (donateSection) {
        donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  }, [navigate]);

  // Fetch the project details using the provided ID
  useEffect(() => {
    const fetchProject = async () => {
      try {
        setLoading(true);
        const response = await getProjectById(id);
        console.log('Fetched Project:', response.data); // Debugging: log the fetched data
        setProject(response.data.project);
        setLoading(false);
      } catch (err) {
        setError('Failed to load project data');
        setLoading(false);
      }
    };
    fetchProject();
  }, [id]);

  if (loading) {
    return <Typography variant="h6" sx={{ textAlign: 'center', padding: 4 }}>Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" sx={{ textAlign: 'center', padding: 4 }}>{error}</Typography>;
  }

  // If project is fetched successfully, render it
  if (!project) {
    return <Typography variant="h6" sx={{ textAlign: 'center', padding: 4 }}>No project found!</Typography>;
  }

  // Extract the project data
  const { title, para1, para2, para3, para4, para5, image1, image2, image3, image4, image5 } = project;

  return (
    <Box sx={{ backgroundColor: '#f8f8f8' }}>
      {/* Hero Section */}
      <HeroSection>
        <HeroContent>
          <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xs: '1.5rem', md: '2.5rem' }, marginBottom: 2 }}>
            {title}
          </Typography>
        </HeroContent>
      </HeroSection>

      {/* Blog Content Section */}
      <Container>
        <Grid container spacing={3} sx={{ my: 4 }}>
          <Grid item xs={12} md={12} sx={{ mb: 4 }}>
            <Box sx={{ backgroundColor: 'white', borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.1)', p: 4 }}>
              {/* Paragraph 1 */}
             
              <Box sx={{
  padding: isLargeScreen ? '40px 40px' : '0',
  maxWidth: '80%', // Reduce box width on larger screens
  margin: '0 auto', // Center the box horizontally
}}>
             {image1 && <img src={image1.url} alt="Project Image 1" style={{ 
                width: '100%', 
               
              }} />}
</Box>
              {/* Paragraph 2 */}
              {para2 && <Typography variant="body1" sx={{ mb: 2,textAlign:'justify' }}>{para2}</Typography>}
              <Box sx={{
  padding: isLargeScreen ? '40px 40px' : '0',
  maxWidth: '80%', // Reduce box width on larger screens
  margin: '0 auto', // Center the box horizontally
}}>
              {image2 && <img src={image2.url} alt="Project Image 2" style={{ 
                width: '100%', 
                marginBottom: '20px',
                padding: isLargeScreen ? '0 20px' : '0', // Add padding on large screens
              }} />}
</Box>
              {/* Paragraph 3 */}
              {para3 && <Typography variant="body1" sx={{ mb: 2 ,textAlign:'justify' }}>{para3}</Typography>}
              <Box sx={{
  padding: isLargeScreen ? '40px 40px' : '0',
  maxWidth: '80%', // Reduce box width on larger screens
  margin: '0 auto', // Center the box horizontally
}}>
              {image3 && <img src={image3.url} alt="Project Image 3" style={{ 
                width: '100%', 
               
              }} />}
</Box>
              {/* Paragraph 4 */}
              {para4 && <Typography variant="body1" sx={{ mb: 2,textAlign:'justify'  }}>{para4}</Typography>}
              <Box sx={{
  padding: isLargeScreen ? '40px 20px' : '0',
  maxWidth:isLargeScreen ? '80%' : '100%',
  margin: '0 auto', // Center the box horizontally
}}>
              {image4 && <img src={image4.url} alt="Project Image 4" style={{ 
                width: '100%', 
                marginBottom: '20px',
                padding: isLargeScreen ? '40px 20px' : '0', // Add padding on large screens
              }} />}

</Box>

  {/* Paragraph 5 */}
  {para5 && <Typography variant="body1" sx={{ mb: 2,textAlign:'justify'  }}>{para5}</Typography>}
              <Box sx={{
  padding: isLargeScreen ? '40px 40px' : '0',
  maxWidth: '80%', // Reduce box width on larger screens
  margin: '0 auto', // Center the box horizontally
}}>
              {image5 && <img src={image5.url} alt="Project Image 5" style={{ 
                width: '100%', 
                marginBottom: '20px',
                padding: isLargeScreen ? '40px 20px' : '0', // Add padding on large screens
              }} />}

</Box>



            </Box>
          </Grid>

          {/* Sidebar Section */}
          <Grid container spacing={2} style={{ marginLeft: 4 }}>
            <Grid item xs={12} lg={4}>
              <SidebarSection>
                <SectionTitle>Recent Projects</SectionTitle>
                <SectionContent>
                  <a href="/lifeschool/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Unique LifeSchool Programme</a>
                </SectionContent>
                <SectionContent>
                  <a href="/gudalur/1" style={{ textDecoration: 'none', color: ' #d40032' }}>From Floor to Bench: Gudalur</a>
                </SectionContent>
                <SectionContent>
                  <a href="/tailoring/1" style={{ textDecoration: 'none', color: ' #d40032' }}>Tailoring Center: Stitching Future</a>
                </SectionContent>
              </SidebarSection>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SidebarSection>
                <SectionTitle>Get Involved</SectionTitle>
                <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
                  Want to make a difference? Check out our programs and see how you can help.
                </Typography>
                <DonateButton fullWidth>Join Us</DonateButton>
              </SidebarSection>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SidebarSection>
                <SectionTitle>Support Our Cause</SectionTitle>
                <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
                  Your donations help us continue our mission to improve education for underprivileged children.
                </Typography>
                <DonateButton fullWidth onClick={scrollToDonate}>Donate</DonateButton>
              </SidebarSection>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
};

export default ProjectDetail;
