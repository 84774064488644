import React from "react";
import { Box, Typography, Button, TextField, Grid, Container } from "@mui/material";
import { useEffect } from 'react';
import Footer from "./Footer";
import { styled } from '@mui/system';

import heroImage from './images/background/detailabout.png';
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  padding: theme.spacing(8, 0),
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#d40032 ',
  color: 'white',
  borderRadius: '25px',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#c0002f',
  },
}));

const ContactUs = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <div>
  <HeroSection>
        <HeroContent>
          <Typography variant="h4" sx={{ fontWeight: 600,  fontSize: { xs: '1.5rem', md: '2.5rem' }, mt: 2 }}>
          Get Touch With Us
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', }}>
          Your Voice Matters: Reach Out and Join Our Mission
          </Typography>
        </HeroContent>
      </HeroSection>
    

      {/* Contact Form Section */}
      <Box sx={{ py: 6, backgroundColor: '#fff' }}>
        <Container>
          <Grid container spacing={6}>
            <Grid item xs={12} md={8}>
              <Typography variant="h4" sx={{ mb: 4, color: '#333', fontWeight: 300 }}>
              
              </Typography>
              <form>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth placeholder="Name" variant="outlined" sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#e0e0e0' } } }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth placeholder="Email" variant="outlined" sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#e0e0e0' } } }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth placeholder="Phone" variant="outlined" sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#e0e0e0' } } }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth placeholder="Subject" variant="outlined" sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#e0e0e0' } } }} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth multiline rows={4} placeholder="Message...." variant="outlined" sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#e0e0e0' } } }} />
                  </Grid>
                </Grid>
                <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <Button variant="contained" sx={{ bgcolor: '#d40032 ', color: '#fff', '&:hover': { bgcolor: '#a00026' }, px: 4, py: 1, borderRadius: '50px' }}>
                    SEND MESSAGE
                  </Button>
                </Box>
              </form>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ bgcolor: '#f9f9f9', p: 4, borderRadius: '8px' }}>
                <Typography variant="h8" sx={{ mb: 3, color: '#333', fontWeight: 500 }}>
                RAY OF LOVE DEVELOPMENT FOUNDATION
                </Typography>
                <Typography sx={{ color: '#37474f', mb: 2,mt:3 }}>
                Cheenivilayil, Pazhavangadi P.O, Ranny, Pathanamthitta, Kerala-689673
                </Typography>
                {/* <Button sx={{ color: '#d40032 ', p: 0, mb: 4, '&:hover': { bgcolor: 'transparent' } }}>
                  YOUR NEAREST LOCATION
                </Button> */}
                <Typography variant="h6" sx={{ mb: 3, color: '#333', fontWeight: 500 }}>
                  Quick Contact
                </Typography>
                <Typography sx={{  mb: 1 }}>
                rayoflovedf@gmail.com


                </Typography>
                <Typography sx={{ mb: 2 }}>
                91-8547326586
                </Typography>
                {/* <Button sx={{ color: '#d40032 ', p: 0, '&:hover': { bgcolor: 'transparent' } }}>
                  GET CALL BACK
                </Button> */}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </div>
  );
};

export default ContactUs;
