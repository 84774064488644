import React, { useState, useEffect } from 'react';
import { Grid, Box, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getCountryBasedProjects } from '../Services/AdminServices';
import { styled } from '@mui/system';

const DonationProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const StyledLink = styled(Link)(({ theme }) => ({
    color: '#302c51',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: '#d40032',
    },
  }));

  const ExploreButton = styled(Button)({
    backgroundColor: '#d40032',
    color: '#fff',
    padding: '10px 20px',
    fontWeight: '600',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#a30026',
    },
  });

  const handleViewMoreClick = () => {
    navigate('/projects');
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getCountryBasedProjects();
        console.log('Projects response:', response);
        
        // Handle the response data properly
        let projectsData = [];
        if (response.data?.projects) {
          projectsData = response.data.projects;
        } else if (response.data) {
          projectsData = response.data;
        }
        
        // Ensure each project has an _id
        const validProjects = projectsData.filter(project => project && project._id);
        console.log('Valid projects:', validProjects);
        
        setProjects(validProjects);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching projects:', err);
        setError('Failed to fetch projects');
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <section className="causes-section-four" style={{ backgroundColor: '#f8f8f8' }}>
      <div className="auto-container">
        <div className="sec-title text-center">
          <h1 style={{ marginTop: '-5px' }}>Our Latest Projects</h1>
          <p className="text">
            Transforming Lives through Strategic Initiatives in Education, Health, and Community Empowerment
          </p>
        </div>
        <div className="cause-wrapper">
          <Grid container spacing={3}>
            {projects.map((project) => (
              <Grid item xs={12} sm={6} md={4} key={project._id}>
                <div className="cause-block-four">
                  <div className="inner-box">
                    <div className="image">
                      <img 
                        src={project.image1?.url} 
                        alt={project.title} 
                        style={{
                          width: '100%',
                          height: '200px',
                          objectFit: 'cover',
                        }} 
                      />
                    </div>
                    <div className="lower-content">
                      <div className="wrapper-box">
                        <h4>
                          <StyledLink to={`/project/${project._id}`}>
                            {project.title}
                          </StyledLink>
                        </h4>
                        <div 
  className="text" 
  style={{
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textAlign: 'center',
  }}
>
  {project.para1}
</div>

                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ textAlign: 'center', marginTop: 4 }}>
            <ExploreButton variant="contained" onClick={handleViewMoreClick}>
              View More
            </ExploreButton>
          </Box>
        </div>
      </div>
    </section>
  );
};

export default DonationProjects;