import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, useTheme, useMediaQuery, CircularProgress } from '@mui/material';
import { VisibilityOutlined, ChatBubbleOutline } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { getBlogById } from '../Services/AdminServices';
import { styled } from '@mui/system';
import heroImage from './images/background/detailabout.png';
import Footer from './Footer';

// Styled components
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  padding: theme.spacing(8, 0),
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));

const SidebarSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 6px 24px rgba(0,0,0,0.1)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)({
  fontSize: '1.7rem',
  fontWeight: 600,
  marginBottom: '1rem',
  fontFamily: "'Montserrat', sans-serif",
});

const SectionContent = styled(Typography)({
  fontSize: '1rem',
  color: '#555',
  marginBottom: '1rem',
  fontFamily: "'Open Sans', sans-serif",
});

const DonateButton = styled(Button)({
  backgroundColor: '#d40032',
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: '24px',
  padding: '10px 20px',
  marginTop: '16px',
  fontFamily: "'Open Sans', sans-serif",
  '&:hover': {
    backgroundColor: '#b30029',
  },
});

const BlogDetail = () => {
  const { blogId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Make sure blogId is available
        if (!blogId) {
          throw new Error('Blog ID is missing');
        }

        const response = await getBlogById(blogId);
        console.log('API Response:', response); // Debug log

        if (response?.status === 200 && response?.data?.blog) {
          setBlog(response.data.blog);
        } else {
          throw new Error('Failed to fetch blog data');
        }
      } catch (error) {
        console.error('Error fetching blog:', error);
        setError(error.message || 'Failed to load blog details');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetails();
    window.scrollTo(0, 0);
  }, [blogId]);

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        backgroundColor: '#f8f8f8' 
      }}>
        <CircularProgress sx={{ color: '#d40032' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '50vh',
        gap: 2,
        backgroundColor: '#f8f8f8'
      }}>
        <Typography variant="h6" color="error" sx={{ fontFamily: "'Montserrat', sans-serif" }}>
          {error}
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => navigate('/blog')}
          sx={{ 
            backgroundColor: '#d40032', 
            '&:hover': { backgroundColor: '#b30029' },
            fontFamily: "'Open Sans', sans-serif"
          }}
        >
          Return to Blogs
        </Button>
      </Box>
    );
  }

  if (!blog) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '50vh',
        backgroundColor: '#f8f8f8'
      }}>
        <Typography sx={{ fontFamily: "'Montserrat', sans-serif" }}>Blog not found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', minHeight: '100vh' }}>
      {/* Hero Section */}
      <HeroSection>
        <Box sx={{ 
          zIndex: 1, 
          maxWidth: '800px', 
          padding: theme.spacing(4),
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          <Typography 
            variant="h5" 
            sx={{ 
              fontWeight: 600, 
              fontSize: { xs: '1.5rem', md: '2.5rem' },
              fontFamily: "'Montserrat', sans-serif"
            }}
          >
            {blog.title}
          </Typography>
          {/* <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 400, 
              fontSize: { xs: '1rem', md: '1.5rem' },
              fontFamily: "'Open Sans', sans-serif"
            }}
          >
            {blog.category}
          </Typography> */}
        </Box>
      </HeroSection>

      {/* Blog Content Section */}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={3}>
          {/* Main Content */}
          <Grid item xs={12} md={8}>
            <Box sx={{ 
              backgroundColor: 'white', 
              borderRadius: 2, 
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)', 
              p: 4 
            }}>
              <Box sx={{ mb: 3 }}>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: '#888', 
                    display: 'block', 
                    mb: 1,
                    fontFamily: "'Open Sans', sans-serif"
                  }}
                >
                  {new Date(blog.date).toLocaleDateString()}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: '#555', 
                    mb: 2,
                    fontFamily: "'Open Sans', sans-serif"
                  }}
                >
                  by {blog.author}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <VisibilityOutlined sx={{ fontSize: 20, color: '#d40032' }} />
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        ml: 0.5,
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      {blog.views} views
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ChatBubbleOutline sx={{ fontSize: 20, color: '#d40032' }} />
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        ml: 0.5,
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      {blog.comments} comments
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Blog Image */}
              {blog.imagePath && (
                <Box sx={{ mb: 4 }}>
                  <img 
                    src={blog.imagePath.url} 
                    alt={blog.title}
                    style={{ 
                      width: '100%', 
                      height: 'auto', 
                      borderRadius: '8px' 
                    }} 
                  />
                </Box>
              )}

              {/* Blog Content */}
              <Box 
                sx={{ 
                  '& img': { 
                    maxWidth: '100%', 
                    height: 'auto',
                    borderRadius: '8px',
                    my: 2 
                  }
                }}
              >
                <div 
                  dangerouslySetInnerHTML={{ __html: blog.content }}
                  style={{ 
                    fontFamily: "'Open Sans', sans-serif",
                    lineHeight: 1.6,
                    color: '#333',textAlign:'justify' 
                  }} 
                />
              </Box>
            </Box>
          </Grid>

          {/* Sidebar */}
          <Grid item xs={12} md={4}>
            <SidebarSection>
              <SectionTitle>Recent Projects</SectionTitle>
              <SectionContent>
                <Box 
                  component="a" 
                  href="/lifeschool/1" 
                  sx={{ 
                    textDecoration: 'none', 
                    color: '#d40032',
                    display: 'block',
                    mb: 2,
                    '&:hover': { textDecoration: 'underline' }
                  }}
                >
                  Unique LifeSchool Programme
                </Box>
                <Box 
                  component="a" 
                  href="/gudalur/1" 
                  sx={{ 
                    textDecoration: 'none', 
                    color: '#d40032',
                    display: 'block',
                    mb: 2,
                    '&:hover': { textDecoration: 'underline' }
                  }}
                >
                  From Floor to Bench: Gudalur
                </Box>
                <Box 
                  component="a" 
                  href="/tailoring/1" 
                  sx={{ 
                    textDecoration: 'none', 
                    color: '#d40032',
                    display: 'block',
                    '&:hover': { textDecoration: 'underline' }
                  }}
                >
                  Tailoring Center: Stitching Future
                </Box>
              </SectionContent>
            </SidebarSection>

            <SidebarSection>
              <SectionTitle>Get Involved</SectionTitle>
              <Typography 
                variant="body2" 
                sx={{ 
                  mb: 2, 
                  color: '#555',
                  fontFamily: "'Open Sans', sans-serif"
                }}
              >
                Want to make a difference? Check out our programs and see how you can help.
              </Typography>
              <DonateButton fullWidth>Join Us</DonateButton>
            </SidebarSection>

            <SidebarSection>
              <SectionTitle>Support Our Cause</SectionTitle>
              <Typography 
                variant="body2" 
                sx={{ 
                  mb: 2, 
                  color: '#555',
                  fontFamily: "'Open Sans', sans-serif"
                }}
              >
                Your donations help us continue our mission to improve education for underprivileged children.
              </Typography>
              <DonateButton fullWidth onClick={() => navigate('/donateUs')}>
                Donate
              </DonateButton>
            </SidebarSection>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
};

export default BlogDetail;