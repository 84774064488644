import React, { useCallback } from 'react';
import { Box, Grid, Typography, IconButton, Button } from '@mui/material';
import { Facebook, WhatsApp, YouTube  } from '@mui/icons-material';
import logo5 from './images/logo14.png';
import blogImage1 from './images/resource/client-thumb-1.jpg'; 
import blogImage2 from './images/resource/client-thumb-2.jpg';
import blogImage3 from './images/resource/client-thumb-3.jpg';
import { KeyboardArrowUp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { YoutubeIcon } from 'lucide-react';

const Footer = () => {
  const navigate = useNavigate();
  
  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
      const donateSection = document.getElementById('donate-us-section');
      if (donateSection) {
        donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  }, [navigate]);

  const blogs = [
    { id: 1, image: blogImage1, title: 'How We Changed Lives', link: '#' },
    { id: 2, image: blogImage2, title: 'Helping Hands Together', link: '#' },
    { id: 3, image: blogImage3, title: 'Community Service Stories', link: '#' },
  ];

  return (
    <>
      {/* Desktop and Tablet Footer */}
      <Box component="footer" sx={{ bgcolor: '#1b1b1b', py: 5, color: 'white', display: { xs: 'none', md: 'block' } }}>
        <Box sx={{ maxWidth: 1200, mx: 'auto', px: 2 }}>
          <Grid container spacing={4}>
            {/* Logo and Description */}
            <Grid item xs={12} md={4}>
              <Box>
                <Box component="a" href="index-2.html">
                  <Box component="img" src={logo5} alt="Logo" sx={{ maxWidth: 150 }} />
                </Box>
                <Typography variant="body2" sx={{ mt: 2, color: '#e0e0e0' }}>
                  The great explorer of the truth, the master-builder of human happiness no one rejects, dislikes, or avoids pleasure itself, because it is pleasure.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, color: '#e0e0e0' }}>
                  © 2024 All Rights Reserved.
                </Typography>
              </Box>
            </Grid>

            {/* Contact Details and Newsletter */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Contact Details
              </Typography>
              <Typography variant="body2" sx={{ color: '#e0e0e0' }}>
                Address: RAY OF LOVE DEVELOPMENT FOUNDATION, CHEENIVILAYIL, PAZHAVANGADI P.O, RANNY, PATHANAMTHITTA, KERALA-689673
              </Typography>
              <Typography variant="body2" sx={{ color: '#e0e0e0' }}>
                Phone: 91-8547326586
              </Typography>
              <Typography variant="body2" sx={{ color: '#e0e0e0' }}>
                Email: rayoflovedf@gmail.com
              </Typography>
            </Grid>

            {/* Donate Now Box */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Donate Now
              </Typography>
              <Typography variant="body2" sx={{ mb: 2, color: '#e0e0e0' }}>
                Your generous donation helps us provide resources, support, and services to those in need. Every contribution, no matter how small, makes a difference.
              </Typography>
              <Button variant="contained" color="secondary" sx={{ bgcolor: '#d40032 ', '&:hover': { bgcolor: '#ff1744' } }} onClick={scrollToDonate}>
                Donate Now
              </Button>
            </Grid>
          </Grid>

          {/* Links for Terms and Policies */}
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} textAlign="center">
              
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                {[
                  { label: 'Terms and Conditions', link: '/terms' },
                  { label: 'Privacy Policy', link: '/privacy' },
                  { label: 'Shipping Policy', link: '/shipping' },
                  { label: 'Cancellation and Refunds', link: '/refunds' },
                ].map((item, index) => (
                  <Typography
                    key={index}
                    component="a"
                    href={item.link}
                    variant="body2"
                    sx={{ color: '#e0e0e0', '&:hover': { color: '#d40032 ' } }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Social Media Links */}
        <Box sx={{ bgcolor: '#333', py: 2, mt: 3 }}>
  <Box sx={{ maxWidth: 1200, mx: 'auto', px: 2 }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
      {[
        { icon: <Facebook />, label: 'Facebook' },
        { icon: <WhatsApp />, label: 'WhatsApp' },
        { icon: <YouTube />, label: 'YouTube' }
      ].map((social, index) => (
        <IconButton key={index} href="#" sx={{ color: '#fff', '&:hover': { color: '#d40032 ' } }}>
          {social.icon}
          <Typography variant="body2" sx={{ ml: 1 }}>
            {social.label}
          </Typography>
        </IconButton>
      ))}
    </Box>
  </Box>
</Box>

      </Box>

      {/* Mobile Footer */}
      <Box
        component="footer"
        sx={{
          bgcolor: '#1b1b1b',
          py: 2,
          color: 'white',
          display: { xs: 'block', md: 'none' },
          textAlign: 'center',
        }}
      >
        <Typography variant="body2" sx={{ mb: 1 }}>
          Follow Us:
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
          <IconButton href="#" sx={{ color: '#fff', '&:hover': { color: '#d40032 ' } }}>
            <Facebook />
          </IconButton>
          <IconButton href="#" sx={{ color: '#fff', '&:hover': { color: '#d40032 ' } }}>
            <WhatsApp />
          </IconButton>
          <IconButton href="#" sx={{ color: '#fff', '&:hover': { color: '#d40032 ' } }}>
            <YouTube />
          </IconButton>
         
        
        </Box>
        <Typography variant="caption" sx={{ color: '#999', mt: 2 }}>
          © 2009–2024 All Rights Reserved.
        </Typography>
      </Box>

      {/* Back to Top Button */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          bgcolor: '#d40032 ',
          color: '#fff',
          borderRadius: '50%',
          width: 40,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          fontSize: 24,
          '&:hover': { bgcolor: '#ff1744' }
        }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <KeyboardArrowUp />
      </Box>
    </>
  );
};

export default Footer;
