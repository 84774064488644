import React, { useEffect, useState } from 'react';
import { Box, Grid, Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { LocationOn, AccessTime } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getCountryBasedEvents } from '../Services/AdminServices';
import event1 from './images/about/portfolio5.jpg';

const EventCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  overflow: 'hidden',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0 8px 40px rgba(0,0,0,0.2)',
  },
}));

const EventImage = styled('img')({
  width: '100%',
  height: '200px',  // Fixed height for the images
  objectFit: 'cover', // Ensures the image covers the box while maintaining aspect ratio
  borderRadius: '8px',
});

const EventDate = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  left: '10px',
  backgroundColor: '#d40032',
  padding: '8px 12px',
  color: '#fff',
  borderRadius: '5px',
  fontWeight: 'bold',
  fontSize: '1.2rem',
}));

const EventContent = styled(Box)(({ theme }) => ({
  padding: '16px',
  backgroundColor: '#fff',
  borderRadius: '0 0 8px 8px',
  position: 'relative',
  zIndex: 1,
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: '#333',
  marginBottom: '8px',
  fontSize: '1.1rem',
}));

const EventDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
  fontSize: '0.9rem',
  color: '#555',
}));

const ExploreButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#d40032',
  color: '#fff',
  padding: '10px 20px',
  fontWeight: '600',
  borderRadius: '30px',
  '&:hover': {
    backgroundColor: '#a30026',
  },
}));

const SectionHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '2rem',
  color: '#333',
  marginBottom: '16px',
}));

const EventSection = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await getCountryBasedEvents();
        console.log('API Response:', response); // Debug log

        if (response?.data?.success && Array.isArray(response.data.events)) {
          const sortedEvents = response.data.events
            .sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate))
            .slice(0, 3);  // Display only the latest 3 events
          setEvents(sortedEvents);
        } else {
          setEvents([]);
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to load events.');
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleViewMoreClick = () => {
    navigate('/eventsPage');
  };

  
  if (loading) {
    return <Typography>Loading events...</Typography>;
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  return (
    <Box sx={{ py: 8, backgroundColor: '#f8f8f8' }}>
      <Container maxWidth="lg">
        <div className="sec-title text-center" style={{ marginTop: '-50px' }}>
          <SectionHeading align="center">Our Events</SectionHeading>
          <Typography variant="body1" sx={{ color: '#777' }} align="center">
            Hosting Landmark Events that Drive Change, Foster Connections, and Shape the Future of Communities
          </Typography>
        </div>
        <Grid container spacing={3}>
          {events.length > 0 ? (
            events.map((event) => (
              <Grid item xs={12} lg={4} key={event._id}>
                <EventCard onClick={() => (event._id)}>
                  <EventImage src={event.imagePath?.url || event1} alt={event.title} />
                  <EventDate>
  {new Date(event.eventDate).getDate()}{" "}
  {new Date(event.eventDate).toLocaleString("default", { month: "short" })}{" "}
  {new Date(event.eventDate).getFullYear()}
</EventDate>

                  <EventContent>
                    <EventTitle variant="h6">{event.title || 'Event Title'}</EventTitle>
                    <EventDetails>
  <AccessTime sx={{ fontSize: '1rem', marginRight: '8px', color: '#d40032' }} />
  <Typography variant="body2">
    {event.eventTime
      ? new Date(`1970-01-01T${event.eventTime}`).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
      : 'Time not available'}
  </Typography>
</EventDetails>


                    <EventDetails>
                      <LocationOn sx={{ fontSize: '1rem', marginRight: '8px', color: '#d40032' }} />
                      <Typography variant="body2">{event.eventLocation || 'Location not available'}</Typography>
                    </EventDetails>
                  </EventContent>
                </EventCard>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" align="center">No events available at the moment.</Typography>
            </Grid>
          )}
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <ExploreButton variant="contained" onClick={handleViewMoreClick}>
            Explore More Events
          </ExploreButton>
        </Box>
      </Container>
    </Box>
  );
};

export default EventSection;
