import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  TextField, 
  Button, 
  InputAdornment, 
  IconButton, 
  Snackbar, 
  Alert, 
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel, 
  Select, 
  MenuItem 
} from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { postProject, updateProject } from '../Services/AdminServices';

// Styled components
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#302c51',
  textAlign: 'center',
  marginBottom: theme.spacing(6),
  fontFamily: "'Montserrat', sans-serif",
}));

const FormField = styled(TextField)(({ theme, error }) => ({
  marginBottom: theme.spacing(3),
  ...(error && {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
    },
  }),
}));

const ImagePreview = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: 200,
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const AdminProjectsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const existingProject = location.state?.project;

  // Country options
  const countryOptions = [
    { value: 'US', label: 'United States' },
    { value: 'IN', label: 'India' },
    // Add more countries as needed
  ];

  // Form States
  const [title, setTitle] = useState('');
  const [para1, setPara1] = useState('');
  const [para2, setPara2] = useState('');
  const [para3, setPara3] = useState('');
  const [para4, setPara4] = useState('');
  const [para5, setPara5] = useState('');
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [imagePreview1, setImagePreview1] = useState('');
  const [imagePreview2, setImagePreview2] = useState('');
  const [imagePreview3, setImagePreview3] = useState('');
  const [imagePreview4, setImagePreview4] = useState('');
  const [imagePreview5, setImagePreview5] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Error and notification states
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [errors, setErrors] = useState({
    title: false,
    countries: false,
  });

  // Initialize form with existing project data
  useEffect(() => {
    if (existingProject) {
      try {
        // Parse countries data
        let parsedCountries = [];
        if (existingProject.countries) {
          if (Array.isArray(existingProject.countries)) {
            parsedCountries = existingProject.countries;
          } else if (typeof existingProject.countries === 'string') {
            parsedCountries = JSON.parse(existingProject.countries);
          }
        }

        // Set all form fields
        setTitle(existingProject.title || '');
        setPara1(existingProject.para1 || '');
        setPara2(existingProject.para2 || '');
        setPara3(existingProject.para3 || '');
        setPara4(existingProject.para4 || '');
        setPara5(existingProject.para5 || '');
        setSelectedCountries(parsedCountries);
        setImagePreview1(existingProject.image1?.url || '');
        setImagePreview2(existingProject.image2?.url || '');
        setImagePreview3(existingProject.image3?.url || '');
        setImagePreview4(existingProject.image4?.url || '');
        setImagePreview5(existingProject.image5?.url || '');
      } catch (error) {
        console.error('Error parsing project data:', error);
        setSelectedCountries([]);
      }
    }
  }, [existingProject]);

  // Handle country selection
  const handleCountryChange = (countryValue) => {
    setSelectedCountries((prev) => {
      const newSelection = prev.includes(countryValue)
        ? prev.filter((c) => c !== countryValue)
        : [...prev, countryValue];
      return newSelection;
    });
    setErrors((prev) => ({ ...prev, countries: false }));
  };

  // Handle image upload
  const handleImageChange = (event, imageSetter, previewSetter) => {
    const file = event.target.files[0];
    if (file) {
      imageSetter(file);
      previewSetter(URL.createObjectURL(file));
    }
  };

  // Form validation
  const validateFields = () => {
    const newErrors = {
      title: !title.trim(),
      
    };
    setErrors(newErrors);
    return Object.values(newErrors).every(error => !error);
  };

  // Form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!validateFields()) {
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('title', title.trim());
    formData.append('para1', para1.trim());
    formData.append('para2', para2.trim());
    formData.append('para3', para3.trim());
    formData.append('para4', para4.trim());
    formData.append('para5', para5.trim());
    formData.append('countries', JSON.stringify(selectedCountries || []));

    // Append images only if they exist
    if (image1 instanceof File) formData.append('image1', image1);
    if (image2 instanceof File) formData.append('image2', image2);
    if (image3 instanceof File) formData.append('image3', image3);
    if (image4 instanceof File) formData.append('image4', image4);
    if (image5 instanceof File) formData.append('image5', image5);

    try {
      let response;
      if (existingProject) {
        response = await updateProject(existingProject._id, formData);
      } else {
        response = await postProject(formData);
      }

      if (response.status === 200 || response.status === 201) {
        setSnackbar({
          open: true,
          message: existingProject ? 'Project updated successfully!' : 'Project created successfully!',
          severity: 'success',
        });
        setTimeout(() => navigate('/admin'), 2000);
      } else {
        throw new Error(existingProject ? 'Failed to update project' : 'Failed to create project');
      }
    } catch (error) {
      console.error('Error details:', error.response ? error.response.data : error.message);
      setSnackbar({
        open: true,
        message: `Error occurred while ${existingProject ? 'updating' : 'creating'} the project: ${error.message}`,
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Navigation handlers
  const handleCancel = () => {
    navigate('/admin/projects');
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', py: 10 }}>
      <Container maxWidth="lg">
        <SectionTitle variant="h2">
          {existingProject ? 'Edit Project' : 'Create New Project'}
        </SectionTitle>

        <Box component="form" onSubmit={handleSubmit}>
          {/* Country Selection */}
          <FormGroup row>
            {countryOptions.map((country) => (
              <FormControlLabel
                key={country.value}
                control={
                  <Checkbox
                    checked={selectedCountries.includes(country.value)}
                    onChange={() => handleCountryChange(country.value)}
                  />
                }
                label={country.label}
              />
            ))}
          </FormGroup>

         <Typography variant="body2" color="textSecondary" sx={{ mb: 3}}>
           If no country is selected, this project will be visible globally.
         </Typography>

          {/* Title Field */}
          <FormField
            fullWidth
            label="Project Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={errors.title}
            helperText={errors.title ? "Title is required" : ""}
            required
          />

          {/* Paragraph Fields */}
          <FormField
            fullWidth
            label="Short Description"
            variant="outlined"
            multiline
            rows={4}
            value={para1}
            onChange={(e) => setPara1(e.target.value)}
          />

          <FormField
            fullWidth
            label="Paragraph 1"
            variant="outlined"
            multiline
            rows={4}
            value={para2}
            onChange={(e) => setPara2(e.target.value)}
          />

          <FormField
            fullWidth
            label="Paragraph 2"
            variant="outlined"
            multiline
            rows={4}
            value={para3}
            onChange={(e) => setPara3(e.target.value)}
          />

          <FormField
            fullWidth
            label="Paragraph 3"
            variant="outlined"
            multiline
            rows={4}
            value={para4}
            onChange={(e) => setPara4(e.target.value)}
          />

          <FormField
            fullWidth
            label="Paragraph 4"
            variant="outlined"
            multiline
            rows={4}
            value={para5}
            onChange={(e) => setPara5(e.target.value)}
          />

          {/* Image Upload Fields */}
          {[
            { num: '1', state: image1, setState: setImage1, preview: imagePreview1, setPreview: setImagePreview1 },
            { num: '2', state: image2, setState: setImage2, preview: imagePreview2, setPreview: setImagePreview2 },
            { num: '3', state: image3, setState: setImage3, preview: imagePreview3, setPreview: setImagePreview3 },
            { num: '4', state: image4, setState: setImage4, preview: imagePreview4, setPreview: setImagePreview4 },
            { num: '5', state: image5, setState: setImage5, preview: imagePreview5, setPreview: setImagePreview5 },
          ].map((item) => (
            <Box key={item.num} sx={{ mb: 3 }}>
              <FormField
                fullWidth
                label={`Project Image ${item.num}`}
                variant="outlined"
                value={item.state ? item.state.name : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton component="label">
                        <AttachFile />
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          onChange={(e) => handleImageChange(e, item.setState, item.setPreview)}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                readOnly
              />
              {item.preview && (
                <ImagePreview src={item.preview} alt={`Preview ${item.num}`} />
              )}
            </Box>
          ))}

          {/* Action Buttons */}
          <Box sx={{ textAlign: 'center', mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: 600,
                px: 4,
                py: 1.5,
                color: "#302c51",
                borderColor: "#302c51",
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              sx={{
                backgroundColor: '#d40032',
                '&:hover': { backgroundColor: '#b8002d' },
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: 600,
                px: 4,
                py: 1.5,
              }}
            >
              {isLoading 
                ? (existingProject ? 'Updating...' : 'Creating...') 
                : (existingProject ? 'Update Project' : 'Create Project')
              }
            </Button>
          </Box>
        </Box>

        {/* Notification Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{
              width: '100%',
              backgroundColor: 'white',
              color: '#d40032',
              '& .MuiSvgIcon-root': {
                color: '#d40032',
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default AdminProjectsPage;