import React, { useCallback, useState, useEffect } from 'react';
import { Box, Typography, Grid, Container, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import heroImage from './images/background/detailabout.png';
import {getCountryBasedProjects } from '../Services/AdminServices';

// Constants
const FALLBACK_IMAGE = '/placeholder-image.jpg';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));

const ProjectCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '12px',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const ProjectImage = styled('div')({
  position: 'relative',
  width: '100%',
  paddingTop: '66.67%', // 3:2 aspect ratio
  overflow: 'hidden',
  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const ProjectContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
}));

const ProjectTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  color: '#302c51',
}));

const ProjectDescription = styled(Typography)(({ theme }) => ({
  color: '#666',
  marginBottom: theme.spacing(2),
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexGrow: 1,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#d40032',
  color: 'white',
  borderRadius: '25px',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#c0002f',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(4),
  position: 'relative',
  display: 'inline-block',
  color: '#302c51',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    backgroundColor: '#d40032',
  },
}));

const SectionDescription = styled(Typography)(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  marginBottom: theme.spacing(6),
  textAlign: 'center',
  color: '#555',
}));

const ProjectCardComponent = ({ project }) => {
  const navigate = useNavigate();
  const imageUrl = project.image1?.url || FALLBACK_IMAGE;

  const handleClick = () => {
    navigate(`/project/${project._id}`);
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <ProjectCard onClick={handleClick} sx={{ cursor: 'pointer' }}>
        <ProjectImage>
          <img
            src={imageUrl}
            alt={project.title}
            onError={(e) => {
              if (e.target.src !== FALLBACK_IMAGE) {
                e.target.src = FALLBACK_IMAGE;
              }
            }}
          />
        </ProjectImage>
        <ProjectContent>
          <ProjectTitle variant="h5">
            {project.title}
          </ProjectTitle>
          <ProjectDescription variant="body2">
            {project.para1}
          </ProjectDescription>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 'auto', pt: 2 }}>
            {new Date(project.date).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </Typography>
        </ProjectContent>
      </ProjectCard>
    </Grid>
  );
};

function Projectpage() {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const fetchProjects = async () => {
      try {
        setIsLoading(true);
        const response = await getCountryBasedProjects();
        if (response.data.success) {
          const processedProjects = response.data.projects.map(project => ({
            ...project,
            image1: project.image1 || null // Ensure image1 is always defined
          }));
          setProjects(processedProjects);
        } else {
          setError(response.data.message || 'Failed to fetch projects');
        }
      } catch (err) {
        setError('Failed to fetch projects. Please try again later.');
        console.error('Error fetching projects:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const scrollToDonate = useCallback(() => {
    navigate('/donateUs');
    setTimeout(() => {
      const donateSection = document.getElementById('donate-us-section');
      if (donateSection) {
        donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  }, [navigate]);

  return (
    <>
      <HeroSection>
        <HeroContent>
          <Typography variant="h4" sx={{ fontWeight: 600, fontSize: { xs: '1.5rem', md: '2.5rem' }, mt: 2 }}>
            Explore Our Projects
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
            Engage in Meaningful Projects that Inspire Hope
          </Typography>
        </HeroContent>
      </HeroSection>

      <Box sx={{ py: 8, backgroundColor: 'white' }}>
        <Container>
          <SectionTitle variant="h4" sx={{ textAlign: 'center' }}>
            Our Projects
          </SectionTitle>
          
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography color="error">{error}</Typography>
            </Box>
          ) : (
            <Grid container spacing={4}>
              {projects.map((project) => (
                <ProjectCardComponent key={project._id} project={project} />
              ))}
            </Grid>
          )}
        </Container>
      </Box>

      <Box sx={{ py: 6, backgroundColor: '#f9f9f9', textAlign: 'center' }}>
        <Container>
          <SectionTitle variant="h4">Be Part of the Impact</SectionTitle>
          <SectionDescription variant="body1">
            Get involved in our initiatives to uplift communities. Your support, whether through volunteering or donations, 
            helps provide essential resources and create lasting change.
          </SectionDescription>
          <StyledButton variant="contained" size="large" onClick={scrollToDonate}>
            Support for a Cause
          </StyledButton>
        </Container>
      </Box>

      <Footer />
    </>
  );
}

export default Projectpage;