import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, IconButton, Button, Stack, Divider, Menu, MenuItem, useMediaQuery, useTheme, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Search, Menu as MenuIcon, LocalMall, ExpandMore, Close } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faSkype } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope as EnvelopeIcon, faPhone as PhoneIcon } from '@fortawesome/free-solid-svg-icons';
import logo4 from './images/logo12.png';
import '../index.css';

const Header = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const scrollToDonate = useCallback(() => {
        const donateSection = document.getElementById('donate-us-section');
        if (donateSection) {
            donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            navigate('/?scrollTo=donate');
        }
    }, [navigate]);

    const SidebarContent = () => (
        <Box sx={{ 
            width: 250, 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            {/* Header with Logo and Close Button */}
            <Box>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    p: 1.5,
                    borderBottom: '1px solid #e0e0e0'
                }}>
                    <Box sx={{ width: '100px' }}>
                        <img 
                            src={logo4} 
                            alt="Logo" 
                            style={{ 
                                width: '100%',
                                height: 'auto',
                                objectFit: 'contain'
                            }} 
                        />
                    </Box>
                    <IconButton onClick={toggleSidebar} size="small">
                        <Close />
                    </IconButton>
                </Box>

                <Box sx={{ 
                    p: 1.5, 
                    borderBottom: '1px solid #e0e0e0',
                    bgcolor: '#f8f8f8',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            fontSize: '13px',
                            fontStyle: 'italic',
                            color: '#666'
                        }}
                    >
                        Together, we can change lives...
                    </Typography>
                    <Button
    color="primary"
    size="small"
    onClick={() => {
        scrollToDonate();  
        toggleSidebar();   
    }}
    sx={{ 
        color:'#d40032',
        fontSize: '12px',
        ml: 1,
        whiteSpace: 'nowrap'
    }}
>
    Donate Now
</Button>

                </Box>



                {/* Navigation Menu */}
                <List sx={{ py: 0 }}>
                    {[
                        { text: 'Home', path: '/' },
                        { text: 'About', path: '/detailAbout' },
                        { text: 'Donate', path: '/donatePage' },
                        { text: 'Projects', path: '/projects' },
                        { text: 'Events', path: '/eventsPage' },
                        { text: 'Blogs', path: '/blogs' },
                        { text: 'Gallery', path: '/galleryPage' },
                        { text: 'Contact', path: '/contactPage' },
                    ].map((item, index) => (
                        <React.Fragment key={item.text}>
                            <ListItem
                                component={Link}
                                to={item.path}
                                onClick={toggleSidebar}
                                sx={{
                                    py: 1,
                                    minHeight: '40px',
                                    '&:hover': { backgroundColor: '#f0f0f0' }
                                }}
                            >
                                <ListItemText 
                                    primary={item.text}
                                    primaryTypographyProps={{
                                        fontSize: '14px',
                                        fontWeight: 500
                                    }}
                                />
                            </ListItem>
                            {index < 7 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </Box>

            {/* Footer Content */}
            <Box sx={{ p: 1.5, borderTop: '1px solid #e0e0e0', bgcolor: '#f5f5f5' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography variant="body2" sx={{ fontSize: '14px', mr: 1 }}>Language:</Typography>
                    <Button 
                        endIcon={<ExpandMore />} 
                        sx={{ 
                            fontSize: '13px',
                            minWidth: 'unset',
                            p: 0.5
                        }}
                    >
                        En
                    </Button>
                </Box>
                
                <Stack direction="column" spacing={0.5}>
                    <Box display="flex" alignItems="center">
                        <FontAwesomeIcon icon={EnvelopeIcon} style={{ marginRight: 8, fontSize: '12px' }} />
                        <Typography variant="body2" sx={{ fontSize: '12px' }}>
                            rayoflovedf@gmail.com
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <FontAwesomeIcon icon={PhoneIcon} style={{ marginRight: 8, fontSize: '12px' }} />
                        <Typography variant="body2" sx={{ fontSize: '12px' }}>
                            91-8547326586
                        </Typography>
                    </Box>
                </Stack>

                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'flex-start',
                    mt: 1 
                }}>
                    {[faFacebook, faTwitter, faSkype].map((icon, index) => (
                        <IconButton 
                            key={index} 
                            size="small" 
                            sx={{ 
                                mr: 1,
                                p: 0.5
                            }}
                        >
                            <FontAwesomeIcon icon={icon} style={{ fontSize: '14px' }} />
                        </IconButton>
                    ))}
                </Box>
            </Box>
        </Box>
    );
    return (
        <Box component="header" className="header">
            {/* {isMobile && <Box sx={{ height: '40px', backgroundColor: 'black', width: '100%' }} />} */}

            {/* {isMobile && (
    <Box className="top-bar">
        <Grid container className="top-bar-content">
            <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center">
                    <Button
                        endIcon={<ExpandMore />}
                        className="language-button"
                        aria-controls="language-menu"
                        aria-haspopup={true}
                    >
                        En
                    </Button>
                    <Typography className="donate-text">
                        Together, we can change lives.... 
                        <Button sx={{ color: 'white' }} onClick={() => {
                            scrollToDonate();  
                            toggleSidebar();  
                        }}>Donate Now.</Button>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'right' }, mt: { xs: 2, md: 0 } }}>
                <Box className="contact-info">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <FontAwesomeIcon icon={EnvelopeIcon} className="contact-icon" />
                        <Typography sx={{ color: 'white' }}>rayoflovedf@gmail.com</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <FontAwesomeIcon icon={PhoneIcon} className="contact-icon" />
                        <Typography sx={{ color: 'white' }}>91-8547326586</Typography>
                    </Stack>
                    <Box className="social-icons">
                        {[faFacebook, faTwitter, faSkype].map((icon, index) => (
                            <IconButton key={index} className="social-icon-button">
                                <FontAwesomeIcon icon={icon} />
                            </IconButton>
                        ))}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    </Box>
)} */}


            <Box className="main-header">
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={6} md={2}>
                        <Box component="a" href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={logo4} alt="Logo" className="logo" />
                        </Box>
                    </Grid>
                    {isMobile ? (
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button variant="contained" className="donate-button" onClick={scrollToDonate}>
                                Donate Now
                            </Button>
                            <IconButton onClick={toggleSidebar} sx={{ color: 'black' }}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                    ) : (
                        <Grid item xs={6} md={10}>
                            <Box className="nav-menu">
                                <Link to="/" style={{ textDecoration: 'none' }}>
                                    <Button className="nav-button">Home</Button>
                                </Link>
                                <Link to="/detailAbout" style={{ textDecoration: 'none' }}>
                                    <Button className="nav-button">About</Button>
                                </Link>
                                <Link to="/donatePage" style={{ textDecoration: 'none' }}>
                                    <Button className="nav-button">Donate</Button>
                                </Link>
                                <Link to="/projects" style={{ textDecoration: 'none' }}>
                                    <Button className="nav-button">Projects</Button>
                                </Link>
                                <Link to="/eventsPage" style={{ textDecoration: 'none' }}>
                                    <Button className="nav-button">Events</Button>
                                </Link>
                                <Link to="/blogs" style={{ textDecoration: 'none' }}>
                                    <Button className="nav-button">Blogs</Button>
                                </Link>
                                <Link to="/galleryPage" style={{ textDecoration: 'none' }}>
                                    <Button className="nav-button">Gallery</Button>
                                </Link>
                                <Link to="/contactPage" style={{ textDecoration: 'none' }}>
                                    <Button className="nav-button">Contact</Button>
                                </Link>
                                <Button variant="contained" className="donate-button" onClick={scrollToDonate}>
                                    Donate Now
                                </Button>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Drawer 
                open={sidebarOpen} 
                onClose={toggleSidebar} 
                anchor="right"
                PaperProps={{
                    sx: {
                        width:250
                    }
                }}
            >
                <SidebarContent />
            </Drawer>
        </Box>
    );
};

export default Header;