import React, { useState,useCallback } from 'react';
import { Box, Grid, Typography, Button, TextField, Container, Card, CardContent, CardMedia, Paper, Slider, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { FavoriteBorder, LocalHospital, School, ArrowForward } from '@mui/icons-material';
import heroImage from './images/background/detailabout.png';
import img3 from './images/about/img3.jpg'
import img2 from './images/about/img2.jpg'
import img1 from './images/about/img1.jpg'
import Footer from './Footer';
import {  useNavigate } from 'react-router-dom';
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#d40032 ',
  color: 'white',
  '&:hover': {
    backgroundColor: '#b8002d',
    boxShadow: '0 4px 20px rgba(212, 0, 50, 0.25)',
  },
  padding: '16px 32px',
  borderRadius: '50px',
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '1rem',
  transition: 'all 0.3s ease',
  
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(212, 0, 50, 0.5)',
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      borderColor: '#d40032 ',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#d40032 ',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#d40032 ',
 
  },
}));

const ImpactCard = ({ sections }) => (
  <Paper elevation={3} sx={{ 
    p: 4, 
    height: '90%', 
    borderRadius: '12px', 
    transition: 'all 0.3s ease',
    '&:hover': { 
      transform: 'translateY(-10px)',
      boxShadow: '0 12px 30px rgba(0,0,0,0.1)',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  }}>
    {sections.map((section, index) => (
      <Box key={index} sx={{ mb: index === sections.length - 1 ? 0 : 4, width: '100%', textAlign: 'center' }}>
        <Box sx={{ 
          mb: 2, 
          width: 40, 
          height: 40, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundColor: 'rgba(212, 0, 50, 0.1)', 
          borderRadius: '50%', 
          margin: '0 auto'
        }}>
          {section.icon}
        </Box>
        <Typography variant="h6" sx={{ mb: 1, fontWeight: 600, color: '#302c51' }}>
          {section.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2, color: '#555' }}>
          {section.description}
        </Typography>
      </Box>
    ))}
  </Paper>
);
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  padding: theme.spacing(8, 0),
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));
const CauseCard = ({ image, title, description }) => (
  <Card sx={{ 
    height: '100%', 
    display: 'flex', 
    flexDirection: 'column', 
    borderRadius: '12px', 
    overflow: 'hidden', 
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    transition: 'all 0.3s ease',
    '&:hover': { 
      transform: 'translateY(-10px)',
      boxShadow: '0 12px 30px rgba(0,0,0,0.15)',
    },
  }}>
    <CardMedia
      component="img"
      height="200"
      image={image}
      alt={title}
    />
    <CardContent sx={{ flexGrow: 1, p: 3 }}>
      <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 600, mb: 2, color: '#302c51' }}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
      <Button 
        endIcon={<ArrowForward />} 
        sx={{ 
          mt: 3, 
          color: '#d40032 ', 
          fontWeight: 500,
          '&:hover': { 
            backgroundColor: 'rgba(212, 0, 50, 0.1)',
            transform: 'translateX(5px)',
          },
          transition: 'all 0.3s ease',
        }}
      >
        Learn More
      </Button>
    </CardContent>
  </Card>
);

const DonatePage = () => {
  const [donationAmount, setDonationAmount] = useState(50);
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const scrollToDonate = useCallback(() => {
    navigate('/donateUs'); // Navigate to the DonateUs page
    setTimeout(() => { // Delay to allow the page to load
        const donateSection = document.getElementById('donate-us-section');
        if (donateSection) {
            donateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } 
    }, 100); // Adjust the timeout as needed
}, [navigate]);




  const handleDonation = (amount) => {
    setDonationAmount(amount);
  };

  const handleSliderChange = (event, newValue) => {
    setDonationAmount(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>

<HeroSection>
        <HeroContent>
          <Typography variant="h4" sx={{ fontWeight: 600,  fontSize: { xs: '1.5rem', md: '2.5rem' }, mt: 2 }}>
          Empower Change Today
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', }}>
          Join us in
          building a brighter future for communities worldwide.
          </Typography>
        </HeroContent>
      </HeroSection>




      


      <Container maxWidth="lg">
        <Box sx={{ mb: 5,mt:2,  }}>
          
        
        </Box>

        <Grid container spacing={8} sx={{ mb: 8 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h4" gutterBottom sx={{ color: '#302c51', fontWeight: 700, mb: 2}}>
                Why Donate?
              </Typography>
              <Typography variant="body1" sx={{  fontSize: '18px', mb: 3, color: '#616161', lineHeight: 1.8, textAlign: 'justify' }}>
              Your generosity fuels our initiatives and makes a significant impact on the lives of individuals and families in need. Every donation, no matter the size, contributes directly to our mission of empowering communities and fostering growth.Learn about the tangible outcomes of your support. Your donations help us implement educational workshops, sustain community development projects, and support families through challenging times. Discover the difference your contribution can make today.
              </Typography>
            </Box>

            <Paper elevation={3} sx={{ 
              p: 3, 
              borderRadius: '12px', 
              bgcolor: '#f9f9f9', 
              textAlign: 'center',
              '&:hover': {
                backgroundColor: '#ffffff',
                boxShadow: '0 12px 25px rgba(0,0,0,0.15)',
              }
            }}>
                            <Typography variant="h5" sx={{ mb: 2, fontWeight: 600, color: '#302c51' }}>
                Make Your Donation Now
              </Typography>
              <Typography variant="body1" sx={{  fontSize: '16px', mb: 1, color: '#616161', lineHeight: 1.5, textAlign: 'justify' }}>
                Your donation can bring about positive change. Choose an amount that aligns with your capability and values. Every contribution is a step towards a better future. 
              </Typography>
              <StyledButton size="large" sx={{ fontSize: '1.1rem' }} onClick={scrollToDonate}>
                Donate Now
              </StyledButton>
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <ImpactCard
              sections={[
                {
                  icon: <FavoriteBorder sx={{ fontSize: 30, color: '#d40032 ' }} />,
                  title: 'Health Initiatives',
                  description: 'Your support aids in providing medical care ,essential healthcare services, promoting preventive care, and ensuring access to medical resources for marginalized populations.',
                },
                {
                  icon: <LocalHospital sx={{ fontSize: 30, color: '#d40032 ' }} />,
                  title: 'Emergency Relief',
                  description: 'Offering immediate assistance during natural disasters or crises, including food, shelter, and medical aid to those in urgent need.',
                },
                {
                  icon: <School sx={{ fontSize: 30, color: '#d40032 ' }} />,
                  title: 'Educational Programs',
                  description: 'Empowering individuals through access to quality education, vocational training, and skills development, aiming to break the cycle of poverty and promote lifelong learning.',
                }
              ]}
            />
          </Grid>
        </Grid>
        
        {/* <Box sx={{ mb: 12 }}>
          <Typography variant="h4" sx={{ color: '#302c51', fontWeight: 700, mb: 6 }}>
            Choose Your Donation Amount
          </Typography>
          <Box sx={{ maxWidth: '600px', mx: 'auto' }}>
            <Typography variant="body1" sx={{ mb: 2, color: '#555' }}>
              Use the slider to select your donation amount or enter a custom amount in the field below.
            </Typography>
            <Slider
              value={donationAmount}
              min={10}
              max={1000}
              step={10}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="donation-slider"
              sx={{ mb: 4 }}
            />
            <StyledTextField
              fullWidth
              type="number"
              label="Custom Amount"
              variant="outlined"
              value={donationAmount}
              onChange={(e) => handleDonation(Number(e.target.value))}
            />
          </Box>
        </Box> */}
        
        <Box sx={{ textAlign: 'center', mb:5,mt:-4 }}>
          <Typography variant="h4" sx={{ color: '#302c51', fontWeight: 700, mb: 6 }}>
            Impact Stories
          </Typography>
          <Grid container spacing={8}>
            <Grid item xs={12} md={4}>
              <CauseCard
                image={img3}
                title="Life-Changing Education"
                description="Your donation supports educational programs that transform lives and communities."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CauseCard
                image={img2}
                title="Healthcare for All"
                description="Help us provide essential healthcare services and support to those in need."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CauseCard
                image={img1}
                title="Emergency Response"
                description="Support our emergency response efforts to assist communities in times of crisis."
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};

export default DonatePage;

