import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { Container, Box, Typography } from '@mui/material';
import client1 from './images/about/avatar.jpg';
import client2 from './images/about/avatar.jpg';
import client3 from './images/about/avatar.jpg';
import client4 from './images/about/avatar.jpg';
import client5 from './images/about/avatar.jpg';
import 'swiper/css';
import 'swiper/css/navigation';
import back from './images/resource/map-2.png';

const testimonials = [
  {
    "id": 1,
    "image": client1,
    "title": "A New Beginning for Children",
    "text": "Thanks to Ray of Love, my children now have a proper place to sit and study. The benches and desks they provided have made a huge difference in their learning. I can't express how thankful we are for this support.",
    "author": "Sundari Devi",
    "designation": "Parent",
    "company": "Ambilimala School, Ovali Panchayat"
  },
  {
    "id": 2,
    "image": client2,
    "title": "A Lifeline for Women Here",
    "text": "The goat rearing project by Ray of Love has helped many women in our village to become financially independent. We now have a steady income and can support our families with dignity. This project has truly empowered us.",
    "author": "Lakshmi Rani",
    "designation": "Participant, Goat Rearing Project",
    "company": "Ovali Panchayat"
  },
  {
    "id": 3,
    "image": client3,
    "title": "Skills for a Better Future",
    "text": "Through the tailoring training program, I’ve learned skills that have opened new doors for me. I now have the confidence to start my own small business and provide for my family. Thank you, Ray of Love, for believing in me.",
    "author": "Madhavi Kumari",
    "designation": "Tailoring Program Graduate",
    "company": "Ovali Panchayat"
  },
  {
    "id": 4,
    "image": client4,
    "title": "A Beacon of Hope ",
    "text": "The leadership program provided by Ray of Love has brought hope to many in our community. It has equipped us with valuable skills, helping us to work together towards building a stronger, more self-sufficient village.",
    "author": "Rajesh Kumar",
    "designation": "Community Leader",
    "company": "Sea Forth Village, Ovali Panchayat"
  },
  {
    "id": 5,
    "image": client5,
    "title": "Life-Changing Support",
    "text": "The educational supply drive organized by Ray of Love brought essential learning materials to our school. My children now have the books and supplies they need to succeed. We are truly grateful for this support.",
    "author": "Saritha Nair",
    "designation": "Headmistress",
    "company": "Ambilimala School"
  }
];

const Testimonials = () => {
  return (
    <Box
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        backgroundImage: `url(${back})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Container>
        <Typography
          variant="body1"
          sx={{ fontFamily: 'sans-serif', color: '#d40032 ', fontWeight: 600, mb: 2 }}
        >
          FEEDBACKS
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontFamily: 'sans-serif', color: '#302c51', mb: 4 }}
        >
          Testimonials that Have Impact
        </Typography>

        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000 }}
          navigation={true}
          modules={[Navigation, Autoplay]}
          breakpoints={{
            600: {
              slidesPerView: 2,
            },
            900: {
              slidesPerView: 3,
            },
          }}
          className="testimonials-carousel"
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  p: 3,
                  boxShadow: '0px 0px 8px 2px rgba(89, 83, 76, 0.1)',
                  borderRadius: 2,
                  textAlign: 'center',
                  border: '1px solid #ddd',
                }}
              >
                <Box
                  component="img"
                  src={testimonial.image}
                  alt={testimonial.author}
                  sx={{
                    width: { xs: '60px', sm: '80px' },
                    height: { xs: '60px', sm: '80px' },
                    borderRadius: '50%',
                    marginBottom: 2,
                    border: '3px solid #d40032 ',
                  }}
                />
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {testimonial.title}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {testimonial.text}
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                  {testimonial.author}
                </Typography>
                {testimonial.company && ( // Check if company exists before rendering
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {testimonial.designation} - {testimonial.company}
                  </Typography>
                )}
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>

      {/* Swiper arrow button custom styling */}
      <style>
        {`
        .swiper-button-next,
        .swiper-button-prev {
          color: #d40032 ;
          width: 60px;
          height: 60px;
          background-color: rgba(212, 0, 50, 0.2);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
          transition: background 0.3s ease, box-shadow 0.3s ease;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        .swiper-button-next {
          right: 20px;
        }
        .swiper-button-prev {
          left: 20px;
        }
        .swiper-button-next::after,
        .swiper-button-prev::after {
          font-size: 18px;
          color: #000;
          padding: 6px;
        }
        .swiper-button-next:hover,
        .swiper-button-prev:hover {
          background-color: rgba(212, 0, 50, 0.7);
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
        }
      `}
      </style>
    </Box>
  );
};

export default Testimonials;
