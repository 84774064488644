import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { AccessTime, ChevronLeft, ChevronRight } from '@mui/icons-material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import { getCountryBasedBlogs } from '../Services/AdminServices'; // Import the function

const BlogSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sliderRef, setSliderRef] = useState(null);
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await getCountryBasedBlogs(); // Fetch blogs using the service
        // Slice to get only the latest 3 blogs
        const latestBlogs = response.data.blogs.slice(0, 3).map(blog => ({
          title: blog.title,
          category: blog.category,
          imageUrl: blog.imagePath.url,
          author: blog.author,
          date: new Date(blog.createdAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }),
          views: 0, // Add if you implement view tracking
          comments: 0, // Add if you implement comments
          hasVideo: false // Add if you implement video support
        }));
        setBlogs(latestBlogs); // Set the latest 3 blogs
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch blogs');
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const handleBlogClick = () => {
    navigate('/blogs');
  };

  if (loading) {
    return (
      <Box sx={{ py: 8, textAlign: 'center' }}>
        <Typography>Loading blogs...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ py: 8, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ py: { xs: 4, md: 8 }, backgroundColor: '#f8f8f8' }}>
      <Box sx={{ maxWidth: '1200px', margin: '0 auto', px: { xs: 2, md: 4 } }}>
        <Typography variant="subtitle1" sx={{ textAlign: 'center', color: '#d40032', mb: { xs: 1, md: 2 }, fontWeight: 600, textTransform: 'uppercase', letterSpacing: 1.5, fontSize: { xs: '0.8rem', md: '1rem' } }}>
          News & Updates
        </Typography>
        <Typography variant="h4" sx={{ textAlign: 'center', mb: { xs: 3, md: 6 }, color: '#333', fontWeight: 700, fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
          Recent Blog Posts
        </Typography>

        <Box sx={{ position: 'relative', overflow: 'hidden' }}>
          <Slider ref={setSliderRef} {...settings}>
            {blogs.map((post, index) => (
              <Box key={index} sx={{ padding: { xs: 1, md: 2 }, display: 'flex', justifyContent: 'center' }}>
                <Box sx={{
                  borderRadius: 2,
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 6px 25px rgba(0,0,0,0.15)',
                  },
                  width: '100%',
                  maxWidth: { xs: '95%', sm: '90%', md: '85%' },
                }} onClick={handleBlogClick}>
                  <Box sx={{ position: 'relative' }}>
                    <img src={post.imageUrl} alt={post.title} style={{ width: '100%', height: '220px', objectFit: 'cover' }} />
                    <Box sx={{ position: 'absolute', top: 16, left: 16, display: 'flex', gap: 1 }}>
                      <Typography
                        variant="caption"
                        sx={{
                          bgcolor: '#d40032',
                          color: 'white',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1,
                          fontSize: '0.7rem',
                          fontWeight: 'bold',
                        }}
                      >
                        {post.category}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ p: { xs: 2, md: 3 } }}>
                    <Typography variant="caption" sx={{ color: '#d40032', display: 'flex', alignItems: 'center', mb: { xs: 1, md: 2 }, fontSize: { xs: '0.7rem', md: '0.8rem' } }}>
                      <AccessTime sx={{ fontSize: { xs: 12, md: 14 }, mr: 0.5 }} />
                      {post.date}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 700, mb: { xs: 1, md: 2 }, color: '#333', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, lineHeight: 1.4, '&:hover': { color: '#d40032' } }}>
                      {post.title}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ color: '#d40032', fontWeight: 600, fontSize: { xs: '0.8rem', md: '0.9rem' } }}>
                          {post.author}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Slider>

          <IconButton
            onClick={() => sliderRef?.slickPrev()}
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              bgcolor: 'white',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              '&:hover': { bgcolor: '#d40032', color: 'white' },
              zIndex: 1,
            }}
          >
            <ChevronLeft />
          </IconButton>

          <IconButton
            onClick={() => sliderRef?.slickNext()}
            sx={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              bgcolor: 'white',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              '&:hover': { bgcolor: '#d40032', color: 'white' },
              zIndex: 1,
            }}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BlogSection;
