import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, CardActions, Button, IconButton, useTheme, useMediaQuery, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { PostAdd, EventNote, PhotoLibrary, Edit, Delete, ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Snackbar } from '@mui/material';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';

import * as AdminServices from '../Services/AdminServices';

const HomeContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f8f9fa',
  minHeight: '100vh',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#302c51',
  marginBottom: theme.spacing(4),
  fontFamily: "'Montserrat', sans-serif",
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: '0',
    width: '60px',
    height: '4px',
    backgroundColor: '#d40032',
  },
}));

const AdminCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: '#fff',
  boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 30px rgba(0,0,0,0.15)',
  },
}));

const CardIcon = styled(Box)(({ theme }) => ({
  fontSize: '3rem',
  color: '#d40032',
  marginBottom: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const ListContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(3),
  boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(4),
  height: '500px',
  overflowY: 'auto',
}));

const ListItemImage = styled('img')({
  width: '60px',
  height: '60px',
  marginRight: '15px',
  borderRadius: '8px',
  objectFit: 'cover',
});

const adminSections = [
  { title: 'Blog Posts', icon: <PostAdd />, section: 'posts' },
  { title: 'Events', icon: <EventNote />, section: 'events' },
  { title: 'Gallery', icon: <PhotoLibrary />, section: 'gallery' },
  { title: 'Projects', icon: <PhotoLibrary />, section: 'projects' },
];

const AdminHomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [blogPosts, setBlogPosts] = useState([]);
  const [events, setEvents] = useState([]);
  const [projects, setProjects] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const [formData, setFormData] = useState({
    title: '',
    subheading: '',
    category: '',
    content: '',
    author: '',
    image: '',
  });

  const fetchBlogPosts = async () => {
    try {
      const response = await AdminServices.getAllBlogs();
      if (response.data && response.data.success && Array.isArray(response.data.blogs)) {
        setBlogPosts(response.data.blogs);
      } else {
        console.error('Unexpected API response structure:', response.data);
        setBlogPosts([]);
      }
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      setBlogPosts([]);
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await AdminServices.getAllEvents();
      if (response.data && response.data.success && Array.isArray(response.data.events)) {
        setEvents(response.data.events);
      } else {
        console.error('Unexpected API response structure:', response.data);
        setEvents([]);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      setEvents([]);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await AdminServices.getAllProjects();
      console.log(response.data.projects); // Log the project data
      if (response.data && response.data.success && Array.isArray(response.data.projects)) {
        setProjects(response.data.projects);
      } else {
        console.error('Unexpected API response structure:', response.data);
        setProjects([]);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      setProjects([]);
    }
  };
  
  useEffect(() => {
    fetchBlogPosts();
    fetchEvents();
    fetchProjects();
  }, []);

  const handleDeleteConfirmation = (item) => {
    setItemToDelete(item);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        if (itemToDelete.type === 'blog') {
          await AdminServices.deleteBlog(itemToDelete._id);
          fetchBlogPosts();
        } else if (itemToDelete.type === 'event') {
          await AdminServices.deleteEvent(itemToDelete._id);
          fetchEvents();
        } else if (itemToDelete.type === 'project') {
          await AdminServices.deleteProject(itemToDelete._id);
          fetchProjects();
        }
        setShowDeleteSuccess(true);
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    }
    handleCloseDialog();
  };

  const handleLogout = () => {
    setOpenLogoutDialog(true);
  };

  const confirmLogout = async () => {
    try {
      const response = await AdminServices.logout();
      if (response.data.success) {
        sessionStorage.removeItem("authtoken");
        navigate('/login');
      }
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setOpenLogoutDialog(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
    setOpenDialog(false);
    setItemToDelete(null);
    setCurrentItem(null);
  };

  const handleEditEvent = (item) => {
    setCurrentItem(item);
    setFormData({
      title: item.title,
      subheading: item.subheading || '',
      category: item.category || '',
      content: item.content || '',
      author: item.author || '',
      image: item.imagePath?.url || '',
    });
    navigate('/adminEvent', { state: { event: item } });
    setOpenDialog(true);
  };

  const handleEditBlog = (item) => {
    setCurrentItem(item);
    setFormData({
      title: item.title,
      subheading: item.subheading || '',
      category: item.category || '',
      content: item.content || '',
      author: item.author || '',
      image: item.imagePath?.url || '',
    });
    navigate('/adminBlog', { state: { blog: item } });
    setOpenDialog(true);
  };

  const handleEditProject = (item) => {
    setCurrentItem(item);
    setFormData({
      title: item.title,
      subheading: item.subheading || '',
      category: item.category || '',
      content: item.content || '',
      author: item.author || '',
      image: item.imagePath?.url || '',
    });
    navigate('/adminProjects', { state: { project: item } });
    setOpenDialog(true);
  };

  const handleCardClick = (section) => {
    const routes = {
      posts: '/adminBlog',
      events: '/adminEvent',
      gallery: '/adminGallery',
      projects: '/adminProjects'
    };
    navigate(routes[section] || '/');
  };

  const renderBlogItem = (blog) => (
    <Box key={blog._id} display="flex" justifyContent="space-between" alignItems="center" padding={2} borderBottom="1px solid #ddd">
      <Box display="flex" alignItems="center">
        <ListItemImage src={blog.imagePath?.url || ''} alt={blog.title} />
        <Typography variant="body1" style={{ fontWeight: 500 }}>{blog.title}</Typography>
      </Box>
      <Box>
        <IconButton onClick={() => handleEditBlog(blog)} style={{ color: '#302c51' }}>
          <Edit style={{ fontSize: '1rem' }} />
        </IconButton>
        <IconButton onClick={() => handleDeleteConfirmation({ ...blog, type: 'blog' })} style={{ color: '#d40032' }}>
          <Delete style={{ fontSize: '1rem' }} />
        </IconButton>
      </Box>
    </Box>
  );

  const renderEventItem = (event) => (
    <Box key={event._id} display="flex" justifyContent="space-between" alignItems="center" padding={2} borderBottom="1px solid #ddd">
      <Box display="flex" alignItems="center">
        <ListItemImage src={event.imagePath?.url || ''} alt={event.title} />
        <Typography variant="body1" style={{ fontWeight: 500 }}>{event.title}</Typography>
      </Box>
      <Box>
        <IconButton onClick={() => handleEditEvent(event)} style={{ color: '#302c51' }}>
          <Edit style={{ fontSize: '1rem' }} />
        </IconButton>
        <IconButton onClick={() => handleDeleteConfirmation({ ...event, type: 'event' })} style={{ color: '#d40032' }}>
          <Delete style={{ fontSize: '1rem' }} />
        </IconButton>
      </Box>
    </Box>
  );

  const renderProjectItem = (project) => (
    <Box key={project._id} display="flex" justifyContent="space-between" alignItems="center" padding={2} borderBottom="1px solid #ddd">
      <Box display="flex" alignItems="center">
        <ListItemImage src={project.imagePath?.url || '/path/to/default/image.jpg'} alt={project.title} />
        <Typography variant="body1" style={{ fontWeight: 500 }}>{project.title}</Typography>
      </Box>
      <Box>
        <IconButton onClick={() => handleEditProject(project)} style={{ color: '#302c51' }}>
          <Edit style={{ fontSize: '1rem' }} />
        </IconButton>
        <IconButton onClick={() => handleDeleteConfirmation({ ...project, type: 'project' })} style={{ color: '#d40032' }}>
          <Delete style={{ fontSize: '1rem' }} />
        </IconButton>
      </Box>
    </Box>
  );
  

  const renderAdminCard = ({ title, icon, section }) => (
    <Grid item xs={12} sm={6} md={3} key={section}>
      <AdminCard onClick={() => handleCardClick(section)}>
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <CardIcon>{icon}</CardIcon>
          <Typography variant="h5" sx={{ mb: 1, fontFamily: "'Montserrat', sans-serif", fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Manage and organize your {title.toLowerCase()}.
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
          <Button 
            size="small" 
            endIcon={<ArrowForward />} 
            sx={{ 
              color: '#302c51',
              '&:hover': { 
                backgroundColor: 'rgba(48, 44, 81, 0.08)',
              },
            }}
          >
            Create New
          </Button>
        </CardActions>
      </AdminCard>
    </Grid>
  );

  const filteredBlogs = blogPosts.filter(blog =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredEvents = events.filter(event =>
    event.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredProjects = projects.filter(project =>
    project.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <HomeContainer>
      <Container maxWidth="lg">
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
          position: 'relative',
          '@media (max-width:600px)': {
            justifyContent: 'flex-end',
          }
        }}>
          <SectionTitle variant="h2">Admin Dashboard</SectionTitle>
          <IconButton
            onClick={handleLogout}
            style={{
              color: '#d40032',
              fontSize: '0.9rem',
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <PowerSettingsNew />
          </IconButton>
        </Box>

        <Grid container spacing={4}>
          {adminSections.map(renderAdminCard)}
        </Grid>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={4}>
            <ListContainer>
              <Typography variant="h6" sx={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 600 }}>Blog Posts</Typography>
              {filteredBlogs.length > 0 ? (
                filteredBlogs.map(renderBlogItem)
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No blog posts found.
                </Typography>
              )}
            </ListContainer>
          </Grid>
          <Grid item xs={12} md={4}>
            <ListContainer>
              <Typography variant="h6" sx={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 600 }}>Events</Typography>
              {filteredEvents.length > 0 ? (
                filteredEvents.map(renderEventItem)
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No events found.
                </Typography>
              )}
            </ListContainer>
          </Grid>
          <Grid item xs={12} md={4}>
            <ListContainer>
              <Typography variant="h6" sx={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 600 }}>Projects</Typography>
              {filteredProjects.length > 0 ? (
                filteredProjects.map(renderProjectItem)
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No projects found.
                </Typography>
              )}
            </ListContainer>
          </Grid>
        </Grid>
      </Container>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDialog}>
        <DialogTitle>Are you sure you want to delete this item?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          Edit {currentItem?.type === 'blog' ? 'Blog Post' : currentItem?.type === 'event' ? 'Event' : 'Project'}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            name="title"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Subheading"
            type="text"
            fullWidth
            variant="outlined"
            name="subheading"
            value={formData.subheading}
            onChange={(e) => setFormData({ ...formData, subheading: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Category"
            type="text"
            fullWidth
            variant="outlined"
            name="category"
            value={formData.category}
            onChange={(e) => setFormData({ ...formData, category: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Content"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            name="content"
            value={formData.content}
            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Author"
            type="text"
            fullWidth
            variant="outlined"
            name="author"
            value={formData.author}
            onChange={(e) => setFormData({ ...formData, author: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Image URL"
            type="text"
            fullWidth
            variant="outlined"
            name="image"
            value={formData.image}
            onChange={(e) => setFormData({ ...formData, image: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseDialog} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Logout Confirmation Dialog */}
      <Dialog open={openLogoutDialog} onClose={() => setOpenLogoutDialog(false)}>
        <DialogTitle>Are you sure you want to logout?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenLogoutDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmLogout} color="secondary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar
        open={showDeleteSuccess}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message="Item deleted successfully"
        onClose={() => setShowDeleteSuccess(false)}
      />
    </HomeContainer>
  );
};

export default AdminHomePage;