import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  TextField, 
  Button, 
  InputAdornment, 
  IconButton, 
  Snackbar, 
  Alert, 
  Checkbox, 
  FormControlLabel, 
  FormGroup 
} from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { postBlog, updateBlog } from '../Services/AdminServices';

// Styled components
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#302c51',
  textAlign: 'center',
  marginBottom: theme.spacing(6),
  fontFamily: "'Montserrat', sans-serif",
}));

const FormField = styled(TextField)(({ theme, error }) => ({
  marginBottom: theme.spacing(3),
  ...(error && {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
    },
  }),
}));

const ImagePreview = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: 200,
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const BlogPostAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const existingBlog = location.state?.blog;

  // Form States
  const [title, setTitle] = useState('');
  const [subheading, setSubheading] = useState('');
  const [category, setCategory] = useState('');
  const [author, setAuthor] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [content, setContent] = useState('');
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Error and notification states
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [errors, setErrors] = useState({
    title: false,
    subheading: false,
    category: false,
    author: false,
    image: false,
    content: false,
    countries: false,
  });

  // Country options
  const countryOptions = [
    { value: 'US', label: 'United States' },
    { value: 'IN', label: 'India' },
    // Add more countries as needed
  ];

  // Initialize form with existing blog data
  useEffect(() => {
    if (existingBlog) {
      try {
        // Ensure we're working with an array of countries
        let parsedCountries = [];
        if (existingBlog.countries) {
          if (Array.isArray(existingBlog.countries)) {
            parsedCountries = existingBlog.countries;
          } else if (typeof existingBlog.countries === 'string') {
            parsedCountries = JSON.parse(existingBlog.countries);
          }
        }

        setTitle(existingBlog.title || '');
        setSubheading(existingBlog.subheading || '');
        setCategory(existingBlog.category || '');
        setAuthor(existingBlog.author || '');
        setImagePreview(existingBlog.imagePath?.url || '');
        setContent(existingBlog.content || '');
        setSelectedCountries(parsedCountries);
      } catch (error) {
        console.error('Error parsing countries:', error);
        setSelectedCountries([]);
      }
    }
  }, [existingBlog]);

  // Handle country selection
  const handleCountryChange = (countryValue) => {
    setSelectedCountries((prev) => {
      const newSelection = prev.includes(countryValue)
        ? prev.filter((c) => c !== countryValue)
        : [...prev, countryValue];
      return newSelection;
    });
    setErrors((prev) => ({ ...prev, countries: false }));
  };

  // Handle image upload
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setErrors(prev => ({ ...prev, image: false }));
    }
  };

  // Form validation
  const validateFields = () => {
    const newErrors = {
      title: !title.trim(),
      subheading: !subheading.trim(),
      category: !category.trim(),
      author: !author.trim(),
      image: !existingBlog && !image,
      content: !content.trim(),
     
    };
    setErrors(newErrors);
    return Object.values(newErrors).every(error => !error);
  };

  // Form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
  
    if (!validateFields()) {
      setIsLoading(false);
      return;
    }
  
    const formData = new FormData();
    formData.append('title', title.trim());
    formData.append('subheading', subheading.trim());
    formData.append('category', category.trim());
    formData.append('content', content.trim());
    formData.append('author', author.trim());
    
    // Remove validation and ensure empty array is sent when no country is selected
    formData.append('countries', JSON.stringify(selectedCountries || []));

    if (image instanceof File) {
      formData.append('image', image);
    }
  
    try {
      let response;
      if (existingBlog) {
        response = await updateBlog(existingBlog._id, formData);
      } else {
        response = await postBlog(formData);
      }
  
      if (response.status === 200 || response.status === 201) {
        setSnackbar({
          open: true,
          message: existingBlog ? 'Blog updated successfully!' : 'Blog created successfully!',
          severity: 'success',
        });
        setTimeout(() => navigate('/admin'), 2000);
      } else {
        throw new Error(existingBlog ? 'Failed to update blog' : 'Failed to create blog');
      }
    } catch (error) {
      console.error('Error details:', error.response ? error.response.data : error.message);
      setSnackbar({
        open: true,
        message: `Error occurred while ${existingBlog ? 'updating' : 'creating'} the blog: ${error.message}`,
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  

  // Navigation handlers
  const handleCancel = () => {
    navigate(-1);
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', py: 10 }}>
      <Container maxWidth="lg">
        <SectionTitle variant="h2">
          {existingBlog ? 'Edit Blog Post' : 'Post a New Blog'}
        </SectionTitle>

        <Box component="form" onSubmit={handleSubmit}>
          {/* Country Selection */}
          <FormGroup row >
            {countryOptions.map((country) => (
              <FormControlLabel
                key={country.value}
                control={
                  <Checkbox
                    checked={selectedCountries.includes(country.value)}
                    onChange={() => handleCountryChange(country.value)}
                  />
                }
                label={country.label}
              />
            ))}
          </FormGroup>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
  If no country is selected, this blog will be visible globally.
</Typography>

          
          {/* Title Field */}
          <FormField
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={errors.title}
            helperText={errors.title ? "Title is required" : ""}
            required
          />

          {/* Subheading Field */}
          <FormField
            fullWidth
            label="Subheading"
            variant="outlined"
            value={subheading}
            onChange={(e) => setSubheading(e.target.value)}
            error={errors.subheading}
            helperText={errors.subheading ? "Subheading is required" : ""}
            required
          />

          {/* Category Field */}
          <FormField
            fullWidth
            label="Category"
            variant="outlined"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            error={errors.category}
            helperText={errors.category ? "Category is required" : ""}
            required
          />

          {/* Author Field */}
          <FormField
            fullWidth
            label="Author"
            variant="outlined"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            error={errors.author}
            helperText={errors.author ? "Author is required" : ""}
            required
          />

          {/* Image Upload Field */}
          <FormField
            fullWidth
            label="Image"
            variant="outlined"
            value={image ? image.name : ''}
            error={errors.image}
            helperText={errors.image ? "Image is required" : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton component="label">
                    <AttachFile />
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            required={!existingBlog}
            readOnly
          />
          {imagePreview && (
            <ImagePreview src={imagePreview} alt="Preview" />
          )}

          {/* Content Field */}
          <FormField
            fullWidth
            label="Content"
            variant="outlined"
            multiline
            rows={6}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            error={errors.content}
            helperText={errors.content ? "Content is required" : ""}
            required
          />

          {/* Action Buttons */}
          <Box sx={{ textAlign: 'center', mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: 600,
                px: 4,
                py: 1.5,
                color: "#302c51",
                borderColor: "#302c51",
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              sx={{
                backgroundColor: '#d40032',
                '&:hover': { backgroundColor: '#b8002d' },
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: 600,
                px: 4,
                py: 1.5,
              }}
            >
              {isLoading 
                ? (existingBlog ? 'Updating...' : 'Posting...') 
                : (existingBlog ? 'Update Blog' : 'Post Blog')
              }
            </Button>
          </Box>
        </Box>

        {/* Notification Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{
              width: '100%',
              backgroundColor: 'white',
              color: '#d40032',
              '& .MuiSvgIcon-root': {
                color: '#d40032',
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default BlogPostAdmin;