import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  TextField, 
  Button, 
  InputAdornment, 
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { postEvent, updateEvent } from '../Services/AdminServices';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#302c51',
  textAlign: 'center',
  marginBottom: theme.spacing(6),
  fontFamily: "'Montserrat', sans-serif",
}));

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const ImagePreview = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: 200,
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const EventPostAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const existingEvent = location.state?.event;

  // Country options
  const countryOptions = [
    { value: 'US', label: 'United States' },
    { value: 'IN', label: 'India' },
    // Add more countries as needed
  ];

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [title, setTitle] = useState(existingEvent?.title || '');
  const [category, setCategory] = useState(existingEvent?.category || '');
  const [content, setContent] = useState(existingEvent?.content || '');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(existingEvent?.imagePath?.url || '');
  const [eventLocation, setEventLocation] = useState(existingEvent?.eventLocation || '');
  const [eventTime, setEventTime] = useState(existingEvent?.eventTime || '');
  const [eventDate, setEventDate] = useState(existingEvent?.eventDate ? new Date(existingEvent.eventDate).toISOString().split('T')[0] : '');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (existingEvent) {
      setTitle(existingEvent.title);
      setCategory(existingEvent.category);
      setContent(existingEvent.content);
      setImage(existingEvent.imagePath ? existingEvent.imagePath.file : null);
      setImagePreview(existingEvent.imagePath?.url);
      setEventLocation(existingEvent.eventLocation || '');
      setEventTime(existingEvent.eventTime || '');
      setEventDate(existingEvent.eventDate ? new Date(existingEvent.eventDate).toISOString().split('T')[0] : '');
      
      // Parse and set countries if they exist
      if (existingEvent.countries) {
        try {
          const parsedCountries = Array.isArray(existingEvent.countries) 
            ? existingEvent.countries 
            : JSON.parse(existingEvent.countries);
          setSelectedCountries(parsedCountries);
        } catch (error) {
          console.error('Error parsing countries:', error);
          setSelectedCountries([]);
        }
      }
    }
  }, [existingEvent]);

  const handleCountryChange = (countryValue) => {
    setSelectedCountries((prev) => {
      return prev.includes(countryValue)
        ? prev.filter((c) => c !== countryValue)
        : [...prev, countryValue];
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('category', category);
    formData.append('content', content);
    formData.append('countries', JSON.stringify(selectedCountries || []));
    formData.append('eventLocation', eventLocation);
    formData.append('eventTime', eventTime);
    formData.append('eventDate', eventDate);
    if (image) formData.append('image', image);

    try {
      const response = existingEvent 
        ? await updateEvent(existingEvent._id, formData) 
        : await postEvent(formData);

      if (response.status === 200 || response.status === 201) {
        alert(existingEvent ? 'Event updated successfully!' : 'Event created successfully!');
        navigate(-1);
      } else {
        alert(existingEvent ? 'Failed to update event' : 'Failed to create event');
      }
    } catch (error) {
      console.error('Error details:', error.response ? error.response.data : error.message);
      alert(`Error occurred while ${existingEvent ? 'updating' : 'creating'} the event: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure you want to discard your changes?")) {
      navigate(-1);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', py: 10 }}>
      <Container maxWidth="lg">
        <SectionTitle variant="h2">{existingEvent ? 'Edit Event' : 'Create a New Event'}</SectionTitle>
        <Box component="form" onSubmit={handleSubmit}>
          {/* Country Selection */}
          <FormGroup row >
            {countryOptions.map((country) => (
              <FormControlLabel
                key={country.value}
                control={
                  <Checkbox
                    checked={selectedCountries.includes(country.value)}
                    onChange={() => handleCountryChange(country.value)}
                  />
                }
                label={country.label}
              />
            ))}
          </FormGroup>

          <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
            If no country is selected, this event will be visible globally.
          </Typography>

          <FormField
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <FormField
            fullWidth
            label="Category"
            variant="outlined"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />

          {/* New Event-specific fields */}
          <FormField
            fullWidth
            label="Event Location"
            variant="outlined"
            value={eventLocation}
            onChange={(e) => setEventLocation(e.target.value)}
          />
          <FormField
            fullWidth
            label="Event Time"
            variant="outlined"
            value={eventTime}
            onChange={(e) => setEventTime(e.target.value)}
            type="time"
            InputLabelProps={{ shrink: true }}
          />
          <FormField
            fullWidth
            label="Event Date"
            variant="outlined"
            value={eventDate}
            onChange={(e) => setEventDate(e.target.value)}
            type="date"
            InputLabelProps={{ shrink: true }}
          />

          <FormField
            fullWidth
            label="Image"
            variant="outlined"
            value={image ? image.name : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton component="label">
                    <AttachFile />
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            readOnly
          />
          {imagePreview && <ImagePreview src={imagePreview} alt="Image preview" />}
          <FormField
            fullWidth
            label="Short Description "
            variant="outlined"
            multiline
            rows={6}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Box sx={{ textAlign: 'center', mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: 600,
                px: 4,
                py: 1.5,
                color: "#302c51",
                borderColor: "#302c51",
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              sx={{
                backgroundColor: '#d40032',
                '&:hover': { backgroundColor: '#b8002d' },
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: 600,
                px: 4,
                py: 1.5,
              }}
            >
              {isLoading ? (existingEvent ? 'Updating...' : 'Creating...') : (existingEvent ? 'Update Event' : 'Create Event')}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default EventPostAdmin;