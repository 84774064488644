import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Button, Grid, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import heroImage from './images/background/detailabout.png';
import Footer from './Footer';
import { getCountryBasedEvents } from '../Services/AdminServices';

const fallbackImage = "/path_to_fallback_image.jpg";

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${heroImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(4),
  position: 'relative',
  display: 'inline-block',
  color: '#302c51',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    backgroundColor: '#d40032',
  },
}));

const EventCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '12px',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const EventImage = styled('img')({
  width: '100%',
  height: '250px',
  objectFit: 'cover',
});

const EventContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
}));

const EventDate = styled(Typography)(({ theme }) => ({
  color: '#d40032',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  color: '#302c51',
}));

const EventDescription = styled(Typography)(({ theme }) => ({
  color: '#666',
  marginBottom: theme.spacing(2),
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexGrow: 1,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#d40032',
  color: 'white',
  borderRadius: '25px',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#c0002f',
  },
}));

const SectionDescription = styled(Typography)(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  marginBottom: theme.spacing(6),
  textAlign: 'center',
  color: '#555',
}));

const EventPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const fetchEvents = async () => {
      try {
        const response = await getCountryBasedEvents();
        setEvents(response.data.events || []);
      } catch (err) {
        console.error("Error fetching events:", err);
        setError("Failed to load events");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const formatEventDate = (date) => {
    if (!date) return 'Date TBD';
    const eventDate = new Date(date);
    return eventDate.toLocaleDateString('en-US', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const formatEventTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minutes} ${ampm}`;
  };

  const renderEventCard = (event) => {
    return (
      <Grid item xs={12} md={6} key={event._id}>
        <EventCard>
          <EventImage
            src={event.imagePath?.url || fallbackImage}
            alt={event.title}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = fallbackImage;
            }}
          />
          <EventContent>
            <EventDate variant="subtitle1">
              {formatEventDate(event.eventDate)}
            </EventDate>
            <EventTitle variant="h5">{event.title}</EventTitle>
            <EventDescription variant="body2">
              {event.content}
            </EventDescription>
            <EventDate variant="subtitle1" color="text.secondary" sx={{ marginBottom: 2 }}>
              {event.eventLocation} | {formatEventTime(event.eventTime)} | {event.category}
            </EventDate>
          </EventContent>
        </EventCard>
      </Grid>
    );
  };

  return (
    <>
      <HeroSection>
        <HeroContent>
          <Typography variant="h4" sx={{ fontWeight: 600, fontSize: { xs: '1.5rem', md: '2.5rem' }, mt: 2 }}>
            Discover Our Events
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>
            Join us for exciting activities that make a difference.
          </Typography>
        </HeroContent>
      </HeroSection>

      <Box sx={{ py: 4, backgroundColor: '#f9f9f9' }}>
        <Container>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography color="error">{error}</Typography>
            </Box>
          ) : (
            <Grid container spacing={4}>
              {events.length > 0 ? (
                events.map(renderEventCard)
              ) : (
                <Box sx={{ width: '100%', textAlign: 'center', py: 4 }}>
                  <Typography>No events found.</Typography>
                </Box>
              )}
            </Grid>
          )}
        </Container>
      </Box>

      <Box sx={{ py: 10, backgroundColor: 'white', textAlign: 'center' }}>
        <Container>
          <SectionTitle variant="h4">
            Get Involved
          </SectionTitle>
          <SectionDescription variant="body1">
            Interested in volunteering or attending an upcoming event? Learn how you can contribute your time, skills, or resources to support our mission. Together, we can make a difference.
          </SectionDescription>
          <StyledButton 
            variant="contained" 
            size="large" 
            onClick={() => navigate('/contactPage')}
          >
            Join Our Community
          </StyledButton>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default EventPage;