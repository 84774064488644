import React ,{useEffect}from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Button, Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import image1 from './images/about/ray.jpg';
import image2 from './images/about/values1.jpg';
import image3 from './images/about/mainpg.jpg';
import logo from './images/background/logo.png';
import '../index.css'; 

const About = () => {



  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);



  const navigate = useNavigate();

  const handleMoreAboutUsClick = () => {
    navigate('/detailAbout');
  };
  
  return (
    <Box className="about-container">
      <Box className="about-inner-container">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h1" className="about-title">
              Join Us in Spreading<br /> Love and Compassion
            </Typography>
            <Typography variant="h4" className="about-quote">
              "The smallest act of kindness is worth more than the grandest intention."
            </Typography>
            <Typography variant="body1" className="about-description" component="div" style={{ textAlign: 'justify' }}>
            <p style={{ textAlign: 'justify' }}>Ray of Love Development Foundation, we believe that the growth of a community begins with the growth of its families. Our mission is to empower every family to play a key role in the development of their society by providing support, guidance, and resources to establish strong, stable foundations within each family.</p>

  <p style={{ textAlign: 'justify' }}>Through various initiatives, we create environments that uplift, heal, and bring hope to communities in need. Whether through education, leadership development, or fostering unity, our work is built on the idea that when families thrive, entire communities flourish.

   Our holistic approach focuses on emotional, social, and economic well-being, helping families reach their full potential.</p>
</Typography>

            
            <Box className="more-about-button-container">
              <Button variant="outlined" className="more-about-button" onClick={handleMoreAboutUsClick}>
                MORE ABOUT US
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="image-container">
            <Box className="image-wrapper">
              <img src={image1} alt="Main image" className="main-image" />
              <img src={image3} alt="Third image" className="third-image" />
              <img src={logo} alt="Logo icon" className="logo-image" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default About;
